
import { React, useRef, useState, Suspense } from 'react'

import { EffectComposer, DotScreen } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { OrbitControls } from "@react-three/drei";
import { OrthographicCamera } from '@react-three/drei'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

var rotationScale = 4;


export function Model({ mouseX, mouseY }) {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [rx, setRX] = useState(0);
    const [ry, setRY] = useState(Math.PI / 2);

    const refMesh = useRef();
    const { nodes } = useLoader(GLTFLoader, './rook6.glb');

    useFrame(() => {
        setX((mouseY / window.innerHeight - 1) / rotationScale);
        setY(Math.PI / 20 + (mouseX / window.innerWidth - 1) / rotationScale);
        Math.abs(rx - x) < 0.002 ? setRX(x) : setRX(rx + (x - rx) * 0.2);
        Math.abs(ry - y) < 0.002 ? setRY(y) : setRY(ry + (y - ry) * 0.2);
        refMesh.current.rotation.x = rx;
        refMesh.current.rotation.y = ry;
    })

    return (
        <mesh castShadow receiveShadow geometry={nodes.rook_5001.geometry} position={[2, -3.5, 0]} rotation={[0.01, 0.1, 0]} scale={0.015} ref={refMesh}>
            <meshStandardMaterial opacity={1} color={"#222222"} roughness={1} metalness={0} />
        </mesh>
    );
}


function Head({ mouseX, mouseY }) {
    return (
        <Canvas
            style={{ height: '100vh', width: '100vw' }}>
            <OrthographicCamera
                name="Personal Camera"
                makeDefault={true}
                zoom={220}
                far={2000}
                near={0}
                position={[234.55, 140.16, 1063.23]}
            />
            <pointLight position={[15, 15, -25]} intensity={10} />
            <pointLight position={[-25, 15, -15]} intensity={6} />
            <pointLight position={[15, 15, 15]} intensity={1} />
            <pointLight position={[15, -15, -45]} intensity={2} />
            <pointLight color="white" position={[0, 10, 2]} intensity={1} />
            <color attach="background" args={["black"]} />
            <Suspense fallback={null}>
                <Model mouseX={mouseX} mouseY={mouseY} />
                <OrbitControls enableZoom={false} enablePan={false} enableRotate={false}/>
            </Suspense>
            <EffectComposer multisampling={0} disableNormalPass={true}>
                <DotScreen blendFunction={BlendFunction.OVERLAY} angle={Math.PI / 2} scale={0.4} />
            </EffectComposer>
        </Canvas>
    )
}

export default Head;